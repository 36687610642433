<template>
    <div class="main_auth_container">
        <div class="d-flex align-center justify-center auth_container" style="height: 100vh; width: 100%;">
            <el-col :span="8">
                <el-card shadow="always">
                    <el-alert :title="errorMessage" type="error" show-icon v-if="errorAlert" closable @close="closeErrorMessage"/><br>
                    <h3 class="text-flow">Se connecter</h3>
                
                    <div class="spinner-border text-danger" v-if="reponseGotten" style="width: 3rem; height: 3rem;" role="status">
                        <span></span>
                    </div>
                    <form @submit.prevent="login">
                        <div class="form-group mb-3">
                            <label for="user_username" class="col-md-12">Adresse e-mail
                                <input type="text" class="form-control" id="user_username" placeholder="Saisissez votre adresse e-mail"
                                    v-model="user.username" />
                            </label>
                        </div>
                        <div class="form-group mb-3">
                            <label for="user_password" class="col-md-12">Mot de passe
                                <input type="password" id="user_password" class="form-control" placeholder="........"
                                    v-model="user.password" />
                            </label>
                        </div>

                        <a href="#" class="text-flow">Mot de passe oublié</a>
                        <div class="formg-roup mb-3">
                            <button type="submit" class="btn btn-danger col-md-12">Login</button>
                        </div>
                        
                    </form>
                </el-card>
            </el-col>
        </div>
        
    </div>
</template>

<script>
    import { accountService } from '@/_services';
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {
                user: {
                    username: null,
                    password: null,
                },
                errorAlert: false,
                errorMessage: '',
                reponseGotten: false,
            }
        },

        computed: {
            ...mapState('user', ['user']),
        },

        mounted() {
            this.fetchUser();
            this.redirectUserWhenLoggedIn()
        },
        methods: {
            ...mapActions('user', ['fetchUser']),
            async login() {
                
                if (null === this.user.username || null === this.user.password) {
                    this.errorAlert = true;
                    this.errorMessage = 'Email et mot de passe requis';
                    return;
                }
                this.reponseGotten = true;

                try {
                    const response = await accountService.checkUserValidation(this.user.username);
                    
                    if (response.data.status) {
                        try {
                            const res = await accountService.login(this.user);

                            let token = res.data.token;
                            let jwtDecoded = accountService.parseJwt(token)
                            let roles = jwtDecoded.roles;

                            if (roles.includes('ROLE_ADMIN')) {
                                accountService.saveToken(token);
                                this.fetchUser();
                                this.$router.push('/');
                            } else {
                                this.errorAlert = true;
                                this.errorMessage = 'Accès refusé !'
                            }

                        } catch (err) {
                            this.errorAlert = true;
                            this.errorMessage = 'Identifiants sont incorrectes !'
                            console.log(err);
                        }
                        
                        
                        
                        
                    } else {
                        this.errorAlert = true;
                        this.errorMessage = "Email n'est pas encore validé !"
                    }
                } catch (err) {
                    this.errorAlert = true;
                    this.errorMessage = 'Identifiants sont incorrectes !'
                    console.log(err);
                } finally {
                    this.reponseGotten = false;
                }
            },

            redirectUserWhenLoggedIn() {
                if (accountService.isLogged()) {
                    console.log('herre')
                    this.$router.push('/');
                    return;
                }
            },

            closeErrorMessage() {
                this.errorAlert = false;
            }
        },

        

    }
</script>
<style scoped>
    .d-flex {
        display: flex;
    }
    .align-center {
        align-items: center;
    }
    .justify-center {
        justify-content: center;
    }

    .main_auth_container {
        background-color: #E5E5E5;
        height: 100vh;
    }
    .auth_container .el-card {
        padding: 30px 25px;
        border-radius: 10px 10px;
        border: 3px solid #FFFFFF;
        background-color: #FFFFFF;
    }

    .auth_container h3 {
        font-weight: bold;
        margin-bottom: 20px;
    }

    .auth_container p {
        font-weight: bold;
        color: #4E4E4E;
    }
    .auth_container a {
        font-weight: bold;
        color: #476ae8 !important;
        text-decoration: none;
    }

    .auth_container button {
        font-weight: bold;
        margin-top: 15px;
    }
    .auth_container input {
        position: relative;
    }

</style>

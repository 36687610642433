import Axios from "@/_services/caller.service";
import { accountService } from '@/_services';

const userModule = {
  namespaced: true,
  state() {
      return {
          user: null,
      }
  },
  mutations: {
      setUser(state, user) {
        state.user = user;
      }
  },
  getters: {
    getUserEmail(state) {
      return state.user ? state.user.email : null;
    },
  },
  actions: {
      async fetchUser({ commit }) {
          const token = accountService.getToken();
        
          if (!token) {
            return;
          }
        
          try {
            const response = await Axios.get('/session', { headers: { Authorization: `Bearer ${token}` } });
            const { isVerifiedUser, email, roles, lastname, firstname, id } = response.data.user;
            const user = { isVerifiedUser, email, roles, lastname, firstname, id };
            commit('setUser', user);
            localStorage.setItem('user', JSON.stringify(user));
          } catch (error) {
            console.log('Error fetching user:', error);
          }
        }
  },
};

export default userModule;
<template>
    <div class="main_div">
        <div class="container">
            
            <div class="home-filter-nav">
                <el-row class="mb-4">

                    <el-dropdown @command="handleStatusSelect" trigger="click" style="margin-right: 13px;">
                        <el-button type="primary" round plain class="el-dropdown-link">
                            Statut du bien 
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="1">Reçu</el-dropdown-item>
                                <el-dropdown-item command="2">En cours</el-dropdown-item>
                                <el-dropdown-item command="3">Validé</el-dropdown-item>
                                <el-dropdown-item command="4">Refusé</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>

                    </el-dropdown>
                    <el-tag
                        v-for="tag in tags"
                            :key="tag.name"
                            class="mx-1"
                            closable
                            @close="handleClose(tag)"
                            :type="tag.type"
                        >
                        {{ tag.name }}
                    </el-tag>

                    <el-button type="danger" round plain style="position: absolute; right: 10px;">Effacer les filtres {{ count }} résultats</el-button>
                    
                    
                </el-row>
            </div>

            <div class="display_housings">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th></th>
                                <th>BIEN</th>
                                <th>STATUT</th>
                                <th class="d-none d-md-table-cell">Client</th>
                                <th class="d-none d-md-table-cell">Réservation instantanée</th>
                                <th class="d-none d-md-table-cell">Type annulation</th>
                                <th class="d-none d-md-table-cell">PRET A PUBLIER</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in housings" :key="index">
                                <td><input type="checkbox" v-model="selectedRows[index]"></td>
                                <td>{{ item.name }}</td>
                                <td>
                                    <i v-if="item.status === 2" class="fa-solid fa-hourglass-start" style="color: #494fee"></i>
                                    <i v-else-if="item.status === 3" class="fa-solid fa-circle-check" style="color: #1cc43e"></i>
                                    <i v-else-if="item.status === 4" class="fa-solid fa-circle-xmark" style="color: #e2382c"></i>
                                    <i v-else class="fa-solid fa-envelope-circle-check" style="color: #f9a058"></i>
                                    {{ getStatusLabel(item.status) }}
                                </td>
                                <td class="d-none d-md-table-cell"><a href="#">{{ item.user.lastname }} {{ item.user.firstname }}</a></td>
                                <td class="d-none d-md-table-cell">
                                    <el-switch :value="isInstantBooking(item.bookingType)" style="--el-switch-on-color: #f4a261;" disabled/>
                                </td>
                                <td class="d-none d-md-table-cell"><a href="#">{{ item.cancellationPolicy.name }}</a></td>
                                <td class="d-none d-md-table-cell">
                                    <el-switch v-model="item.isHousingValidated"  disabled/>
                                </td>
                                
                                <td>
                                    

                                    <el-button-group class="ml-4">
                                        <el-button style="background-color: #a0db8e; border: 1px solid #a0db8e;">
                                            <router-link :to="{ name: 'admin_housing', params: { id: item.id } }"><i class="fa-solid fa-eye" style="color: #FFF"></i> </router-link>
                                        </el-button>
                                        <el-button :disabled="item.status === 3" @click="showValidateHousingStatusForm(item.id)" style="background-color: #f08080; border: 1px solid #f08080;">
                                            <i class="fa-solid fa-circle-check" style="color: #FFF" ></i>
                                        </el-button>
                                    </el-button-group>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex align-items-start justify-content-between">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :total="countPaginator()" 
                    @current-change="handlePageChange"
                    :current-page="internalPage"
                />
                <el-button type="primary" plain>Total: {{ count }}</el-button>
            </div>
            <br><br>

            <el-dialog v-model="showValidationhousingForm" title="Valider ce bien" width="30%" draggable style="padding: 0px 30px;">
                <h6>Vous êtes sur le point de passer le statut de ce bien a <span style="font-weight: bold; color: #a0db8e">Validé</span></h6>

                <template #footer>
                <span class="dialog-footer">
                    <el-button type="info" plain round @click="showValidationhousingForm = false">Annuler</el-button>
                    <el-button type="success" plain round @click="PassHousingStatusToValid">
                        Valider
                    </el-button>
                </span>
                </template>
            </el-dialog>
        </div>
    </div>
    
</template> 

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';
    import { ElNotification } from 'element-plus';

    export default {
        data() {
            return {
                tags: [],
                housings: [],
                selectedRows: [], 
                statusList: [],
                count: 0, 
                startingPage: 1,
                internalPage: 1,

                selectedHousingForStatusValidation: null,

                showValidationhousingForm: false
            }
        },

        computed: {
            ...mapState('user', ['user']),
            displayStatus(status) {
                switch (status) {
                    case '1':
                        return 'Reçu';
                    case '2':
                        return 'En cours';
                    case '3':
                        return 'Validé';
                    case '4':
                        return 'Refusé';
                    default:
                        return 'Reçu';
                }
            },
        },
    
        mounted() {
            this.fetchUser();
            this.fetchHousings();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            handleClose(tagToRemove) {
                this.tags = this.tags.filter(tag => tag.name !== tagToRemove.name);

                let status = tagToRemove.status
                if (this.statusList.includes(status)) {
                    let index = this.statusList.indexOf(status);
                    if (index > -1) {
                        this.statusList.splice(index, 1);
                    }
                }

                this.filterHousingsAdmin(this.statusList, this.startingPage);
            },

            async fetchHousings() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/housings`);
                        this.housings = response.data.normalizedObject[0]
                        this.count = response.data.normalizedObject['count'];
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            getStatusLabel(status) {
                if (status === 2) {
                    return 'En cours de validation';
                } else if (status === 3) {
                    return 'Validé';
                } else if (status === 4) {
                    return 'Rejeté';
                } else {
                    return 'Reçu';
                }
            },

            handleStatusSelect(command) {
                let status = parseInt(command);
            
                if (!this.statusList.includes(status)) {
                    this.statusList.push(status)
                }
               
                let tag = this.getTagForStatus(status);
                
                // If the tag exists and it's not already in the tags array, add it
                if(tag && !this.tags.some(t => t.name === tag.name)) {
                    this.tags.push(tag);
                }

                this.filterHousingsAdmin(this.statusList, this.startingPage);
            },

            getTagForStatus(status) {
                let tagMapping = {
                    1: { name: 'reçu', type: 'warning', status: 1 },
                    2: { name: 'en cours', type: 'info', status: 2 },
                    3: { name: 'validé', type: 'success', status: 3 },
                    4: { name: 'refusé', type: 'danger', status: 4 },
                };

                return tagMapping[status];
            },

            async filterHousingsAdmin(statuses, page) {
                try {
                    let statusQuery = statuses.map(status => `status[]=${status}`).join('&');
                    let pageQuery = `page=${page}`;
                    let limitQuery = `limit=10`;

                    let combinedQuery = `${statusQuery}&${pageQuery}&${limitQuery}`;
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/filter/housings?${combinedQuery}`);
                        this.housings = response.data.normalizedObject[0];
                        this.count = response.data.normalizedObject['count'];
                    }
                    
                } catch(err) {
                    console.log(err);
                } finally {
                    //this.reponseGotten = false
                }
            },

            handlePageChange(newPage) {
                if (newPage === this.startingPage) return; 

                this.startingPage = newPage;
                this.internalPage = newPage;
                this.filterHousingsAdmin(this.statusList, newPage);
            },

            countPaginator() {
                return this.count
            },

            isInstantBooking(item) {
                switch(item) {
                    case 1:
                        return true;
                    case 0:
                        return false;
                    default:
                        return true;
                }
                    
            },

            showValidateHousingStatusForm(housing_id) {
                this.showValidationhousingForm = true;
                this.selectedHousingForStatusValidation = housing_id
            },

            async PassHousingStatusToValid() {
                try {
                    const response = await Axios.put(`/admin/${this.user.id}/housing/${this.selectedHousingForStatusValidation}/change-status-valid`);
                    this.openNotification('success', response.data.normalizedObject.message)
                    this.fetchHousings()
                    this.showValidationhousingForm = false;

                } catch (error) {
                    console.error(error);
                }
            },

            openNotification(type, message) {
                ElNotification({
                    title: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize the type
                    message: message,
                    type: type,
                })
            },
            
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>

<style>
    
    .main_div {
        background-color: #eef4f6;
        height: 100vh !important;
    }
    .home-filter-nav {
        height: 60px;
        
        position: relative;
        margin-bottom: 6px;
        padding-top: 14px;
    }
    .el-tag {
        position: relative;
        top: 5px;
    }

    .thead-dark th {
        color: #777777;
        font-weight: none;
        font-size: 13px;
    }

    tbody td {
        font-size: 14px;
        height: 4vh;
        line-height: 4vh;
    }
    
    .custom-image-class {
        max-width: 70px; /* Adjust the width as per your requirement */
        height: auto;
    }

    tbody tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }
    thead tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }

</style>
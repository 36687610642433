import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthView from '../views/Security/AuthView.vue'
import HousingsView from '../views/Housings/HousingsView.vue'
import SingleAdminHousingView from '../views/Housings/SingleAdminHousingView.vue'
import OrdersView from '../views/Housings/OrdersView.vue'
import CancellationsView from '../views/Housings/CancellationsView.vue'
import AllOrdersView from '../views/Housings/AllOrdersView.vue'
import paymentWithDocsView from '../views/Housings/paymentWithDocsView.vue'
import ReviewsToValidateView from '../views/Housings/ReviewsToValidateView.vue'
import HostsView from '../views/Housings/HostsView.vue'
import SingleHostView from '../views/Housings/SingleHostView.vue'
import HostDocValidationView from '../views/Housings/HostDocValidationView.vue'
import AppParamsView from '../views/AppParamsView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
  },
  {
    path: '/admin/:id/housings',
    name: 'housings',
    component: HousingsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/housing/:id',
    name: 'admin_housing',
    component: SingleAdminHousingView,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/admin/housing/:id/orders',
    name: 'admin_orders',
    component: OrdersView,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/admin/housing/cancellations',
    name: 'admin_cancellations',
    component: CancellationsView,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/admin/orders',
    name: 'admin_orders_all',
    component: AllOrdersView,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/admin/payment-with-docs',
    name: 'admin_payment_doc',
    component: paymentWithDocsView,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/admin/reviews-to-validate',
    name: 'admin_reviews_to_validate',
    component: ReviewsToValidateView,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/admin/hosts',
    name: 'admin_display_hosts',
    component: HostsView,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/admin/hosts/single-host/:id',
    name: 'admin_display_single_host',
    component: SingleHostView,
    meta: { requiresAuth: true },
    props: true
  },

  {
    path: '/admin/hosts/validate_identity_doc',
    name: 'admin_display_host_identities_doc',
    component: HostDocValidationView,
    meta: { requiresAuth: true },
    props: true
  },

  {
    path: '/admin/parametres',
    name: 'app_params',
    component: AppParamsView,
    meta: { requiresAuth: true },
    props: true
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

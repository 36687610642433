<template>
    <div class="home">
      <Navbar/>
      <PaymentWithDocs/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import PaymentWithDocs from '@/components/Housings/paymentWithDocs.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
        name: 'PaymentWithDocsView',
        components: {
            Navbar,
            PaymentWithDocs
        },
    };
  </script>
  
/**
 * Simple Documentation
 * This file to create different methods to be called directly and this file is exoprted to index.js in the same folder
 * to be reused easily
 */

import Axios from "./caller.service";

let checkUserValidation = async (email) => {
    return await Axios.get(`/auth/verify-user/${email}`);
}
let login = async (credentials) => {
    return await Axios.post('/auth/login', credentials);
}

let logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}

let saveToken = (token) => {
    localStorage.setItem('token', token);
}

let getToken = () => {
    return localStorage.getItem('token');
}

let isLogged = () => {
    try {
        const token = localStorage.getItem('token');
        
        return !!token;
    } catch (e) {
        console.error("Failed to access localStorage:", e);
        return false;
    }
}

let register = async (credentials) => {
    return await Axios.post('/auth/register', credentials);
}

let parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const accountService = {
    checkUserValidation,
    login,
    logout,
    saveToken,
    isLogged,
    getToken,
    register,
    parseJwt
}
<template>
    <div class="container">
        <div class="d-flex" style="margin-top: 20px;">
            <el-col :span="3">
                <div><el-card shadow="never" class="d-flex align-center justify-center card-margin card_1" @click="goToHousings">
                    <i class="fa-solid fa-house-flag fa-2xl"></i>
                    <p>{{ unvalidatedHousings }}</p>
                </el-card></div>
            </el-col>
            <el-col :span="3">
                <el-card shadow="never" class="d-flex align-center justify-center card-margin card_2" @click="goToCancellations">
                    <el-badge :value="pendingCancellationRequests" :max="99" class="item">
                        <i class="fa-solid fa-strikethrough fa-2xl"></i>
                    </el-badge>
                </el-card>  
            </el-col>
            <el-col :span="3">
                <el-card shadow="never" class="d-flex align-center justify-center card-margin card_3" @click="goToReviewsToValidate">
                    <i class="fa-regular fa-star fa-2xl"></i>
                    <p>{{ upcommingReviews }}</p>
                </el-card>  
            </el-col>
            <el-col :span="3">
                <el-card shadow="never" class="d-flex align-center justify-center card-margin card_4" @click="goToHousings">
                    <i class="fa-solid fa-house-circle-check fa-2xl"></i>
                    <p>{{ validatedHousings }}</p>
                </el-card>  
            </el-col>
            <el-col :span="3">
                <el-card shadow="never" class="d-flex align-center justify-center card-margin card_5" @click="goToBookings">
                    <el-badge :value="upcomingBookings" :max="99" class="item" type="success">
                        <i style="color: #008080" class="fa-solid fa-money-bill-transfer fa-2xl"></i>
                    </el-badge>
                </el-card>  
            </el-col>
            <el-col :span="3">
                <el-card shadow="never" class="d-flex align-center justify-center card-margin card_6" @click="goToPaymentsWithDocs">
                    <el-badge :value="upcomingPaymentDocs" :max="99" class="item" type="danger">
                        <i style="color: #db5224" class="fa-solid fa-money-check-dollar fa-2xl"></i>
                    </el-badge>
                </el-card>
            </el-col>

            <el-col :span="3">
                <el-card shadow="never" class="d-flex align-center justify-center card-margin card_7" @click="goToHosts">
                    <el-badge :value="hosts" :max="99" class="item" type="success">
                        <i style="color: #052713" class="fa-solid fa-user fa-2xl"></i>
                        <p>validated hosts</p>
                    </el-badge>
                </el-card>
            </el-col>

            <el-col :span="3">
                <el-card shadow="never" class="d-flex align-center justify-center card-margin card_8" @click="goToHostsDocsValidation">
                    <el-badge :value="hostIdentities" :max="99" class="item" type="danger">
                        <i style="color: #db5224" class="fa-solid fa-id-card fa-2xl"></i>
                        <p>Validate identity </p>
                    </el-badge>
                </el-card>
            </el-col>
        </div>
        
    </div>
</template>

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';

    export default {
        data() {
            return {
                validatedHousings: 0,
                unvalidatedHousings: 0,
                pendingCancellationRequests: 0,
                upcomingBookings: 0,
                upcomingPaymentDocs: 0,
                upcommingReviews: 0,
                hosts: 0,
                hostIdentities: 0,
            }
        },

        computed: {
            ...mapState('user', ['user']),
            
        },

        mounted() {
            this.fetchUser();
            this.getCountValidatedAndUnvalidatedHouisngs();
            this.getPendingCancellationRequests();
            this.getUpcommingBookings();
            this.getPaymentsWithDocs();
            this.getReviewsToValidate();
            this.getAllHosts();
            this.getIdentitiesToVlidate();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            goToHousings() {
                if(this.user.id) {
                    this.$router.push({
                        name: 'housings',
                        params: {id: this.user.id},
                    });
                }
                
            },

            goToBookings() {
                if(this.user.id) {
                    this.$router.push({
                        name: 'admin_orders_all',
                    });
                }
                
            },

            goToCancellations() {
                if(this.user.id) {
                    this.$router.push({
                        name: 'admin_cancellations',
                        params: {id: this.user.id},
                    });
                }
                
            },

            goToPaymentsWithDocs() {
                if(this.user.id) {
                    this.$router.push({
                        name: 'admin_payment_doc',
                    });
                }
            },

            goToReviewsToValidate() {
                if(this.user.id) {
                    this.$router.push({
                        name: 'admin_reviews_to_validate',
                    });
                }
            },

            goToHosts() {
                if(this.user.id) {
                    this.$router.push({
                        name: 'admin_display_hosts',
                    });
                }
            },

            goToHostsDocsValidation() {
                if(this.user.id) {
                    this.$router.push({
                        name: 'admin_display_host_identities_doc',
                    });
                }
            },

            async getCountValidatedAndUnvalidatedHouisngs() {
                try {
                    const response = await Axios.get(`/admin/${this.user.id}/dashboard`);
                    this.validatedHousings = response.data.normalizedObject.validatedHousings;
                    this.unvalidatedHousings = response.data.normalizedObject.unvalidatedHousings;
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async getPendingCancellationRequests() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/cancellation-requests`);
                        this.pendingCancellationRequests = response.data.normalizedObject['count'];
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async getUpcommingBookings() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/upcoming-bookings`);
                        this.upcomingBookings = response.data.normalizedObject['count'];
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async getPaymentsWithDocs() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/upcoming-payment-docs`);
                        this.upcomingPaymentDocs = response.data.normalizedObject['count'];
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async getReviewsToValidate() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/reviews-to-validate`);
                        this.upcommingReviews = response.data.normalizedObject['count'];
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async getAllHosts() {
                try {
                    const response = await Axios.get(`/admin/${this.user.id}/users-with-housings`);
                    this.hosts = response.data.normalizedObject.count
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async getIdentitiesToVlidate() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/identity/upcoming-identity-docs`);
                        this.hostIdentities = response.data.normalizedObject['count'];
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            }
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>

<style>
    .d-flex {
        display: flex;
    }
    .align-center {
        align-items: center;
    }
    .justify-center {
        justify-content: center;
    }
    .card-margin {
        margin: 0 10px;
        height: 150px;
        border-radius: 12px 12px !important;
    }
    .card-margin:hover {
        border: 2px solid #710d23 !important;
    }

    .card_1 {
        background-color: #fef1eb !important;
        border: 1px solid transparent !important;
    }
    .card_2 {
        background-color: #d2f5f7 !important;
        border: 1px solid transparent !important;
    }
    .card_3 {
        background-color: #fff1f4 !important;
        border: 1px solid transparent !important;
    }
    .card_4 {
        background-color: #f1f7cf !important;
        border: 1px solid transparent !important;
    }
    .card_5 {
        background-color: #ffeda4 !important;
        border: 1px solid transparent !important;
    }
    .card_6 {
        background-color: #cfecf0 !important;
        border: 1px solid transparent !important;
    }

    .card_7 {
        background-color: #f3b9a6 !important;
        border: 1px solid transparent !important;
    }

    .card_8 {
        background-color: #ffeda4 !important;
        border: 1px solid transparent !important;
    }
    
    .card_1 i {
        color: #e1af98;
        position: relative;
        top: 15px;
        margin-bottom: 30px !important;
    }
    .card_1 p {
        color:#ef763d;
    }

    .card_2 i {
        color: #0b5f63;
        position: relative;
        top: 15px;
        margin-bottom: 30px !important;
    }
    .card_2 p {
        color:#0b5f63;
        
    }

    .card_3 i {
        color: #710d23;
        position: relative;
        top: 15px;
        margin-bottom: 30px !important;
    }
    .card_3 p {
        color:#710d23;
        
    }

    .card_4 i {
        color: #71830a;
        position: relative;
        top: 15px;
        margin-bottom: 30px !important;
    }
    .card_4 p {
        color:#71830a;
        
    }
    a {
        text-decoration: none;
    }

</style>
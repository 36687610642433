<template>
    <div class="home">
      <Navbar/>
      <AppParams/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import Navbar from '@/components/Navbar/Navbar.vue';
    import AppParams from '@/components/AppParams.vue';
    export default {
      name: 'AppParamsView',
      components: {
        Navbar,
        AppParams
      },
    };
  </script>
  
<template>
    <div class="main_div">
        <div class="container">
            
            <div class="home-filter-nav">
                <el-row class="mb-4">

                    <el-dropdown @command="handleStatusSelect" trigger="click" style="margin-right: 13px;">
                        <el-button type="primary" round plain class="el-dropdown-link">
                            Statut du bien 
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="1">Reçu</el-dropdown-item>
                                <el-dropdown-item command="2">En cours</el-dropdown-item>
                                <el-dropdown-item command="3">Validé</el-dropdown-item>
                                <el-dropdown-item command="4">Refusé</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>

                    </el-dropdown>
                    <el-tag
                        v-for="tag in tags"
                            :key="tag.name"
                            class="mx-1"
                            closable
                            @close="handleClose(tag)"
                            :type="tag.type"
                        >
                        {{ tag.name }}
                    </el-tag>

                    <el-button type="danger" round plain style="position: absolute; right: 10px;">Effacer les filtres {{ count }} résultats</el-button>
                    
                    
                </el-row>
            </div>

            <div class="display_housings">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th class="d-none d-md-table-cell">Nom</th>
                                <th class="d-none d-md-table-cell">test</th>
                                <th class="d-none d-md-table-cell">test</th>
                                <th class="d-none d-md-table-cell">test</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in hosts" :key="index">
                                <td class="d-none d-md-table-cell"><a href="#">{{ item.lastname }} {{ item.firstname }}</a></td>
                                <td class="d-none d-md-table-cell">
                                    {{ item.lastname }}
                                </td>
                                <td class="d-none d-md-table-cell">{{ item.lastname }}</td>
                                <td class="d-none d-md-table-cell">
                                    {{ item.lastname }}
                                </td>
                                
                                <td>
                                    
                                    <el-button-group class="ml-4">
                                        <el-button style="background-color: #a0db8e; border: 1px solid #a0db8e;">
                                            <router-link :to="{ name: 'admin_display_single_host', params: { id: item.id } }"><i class="fa-solid fa-eye" style="color: #FFF"></i> </router-link>
                                        </el-button>
                                        <!--<el-button style="background-color: #f08080; border: 1px solid #f08080;">
                                            <i class="fa-solid fa-circle-check" style="color: #FFF" ></i>
                                        </el-button>-->
                                    </el-button-group>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex align-items-start justify-content-between">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :total="countPaginator()" 
                    @current-change="handlePageChange"
                    :current-page="internalPage"
                />
                <el-button type="primary" plain>Total: {{ count }}</el-button>
            </div>
            <br><br>

            
        </div>
    </div>
    
</template> 

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';
    import { ElNotification } from 'element-plus';

    export default {
        data() {
            return {
                hosts: [],
                hosts_count: null,
                startingPage: 1,
                internalPage: 1,
            }
        },

        computed: {
            ...mapState('user', ['user']),
            
        },
    
        mounted() {
            this.fetchUser();
            this.fetchHosts();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            

            async fetchHosts() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/users-with-housings`);
                        this.hosts_count = response.data.normalizedObject.count
                        this.hosts = response.data.normalizedObject.users
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            handlePageChange(newPage) {
                if (newPage === this.startingPage) return; 

                this.startingPage = newPage;
                this.internalPage = newPage;
                this.filterHousingsAdmin(this.statusList, newPage);
            },

            countPaginator() {
                return this.count
            },

            openNotification(type, message) {
                ElNotification({
                    title: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize the type
                    message: message,
                    type: type,
                })
            },
            
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>

<style>
    
    .main_div {
        background-color: #eef4f6;
        height: 100vh !important;
    }
    .home-filter-nav {
        height: 60px;
        
        position: relative;
        margin-bottom: 6px;
        padding-top: 14px;
    }
    .el-tag {
        position: relative;
        top: 5px;
    }

    .thead-dark th {
        color: #777777;
        font-weight: none;
        font-size: 13px;
    }

    tbody td {
        font-size: 14px;
        height: 4vh;
        line-height: 4vh;
    }
    
    .custom-image-class {
        max-width: 70px; /* Adjust the width as per your requirement */
        height: auto;
    }

    tbody tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }
    thead tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }

</style>
import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import { createStore } from "vuex";
import App from './App.vue'
import router from './router'
import userModule from "./store/modules/user";
import { accountService } from "./_services";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import '@fortawesome/fontawesome-free/css/all.css'


const store = createStore({
    modules: {
        user: userModule,
    },
    state() {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {},
});

// This is to check if any route needs authentication before giving access to the user 
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if(!accountService.isLogged()) {
            next({ path: '/auth', query: { redirect: to.fullPath } });
        } else {
            next();
        }
    } else {
        next();
    }
});


const app = createApp(App)
app.use(ElementPlus);

app.use(store)
app.use(router)

app.mount('#app')
import "bootstrap/dist/js/bootstrap.js"


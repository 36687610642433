<template>
    <div class="home">
      <Navbar/>
      <ReviewsToValidate/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import ReviewsToValidate from '@/components/Housings/ReviewsToValidate.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
        name: 'ReviewsToValidateView',
        components: {
            Navbar,
            ReviewsToValidate
        },
    };
  </script>
  
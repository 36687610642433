<template>
    <div class="main_div">
        <div class="container">
            <div class="home-filter-nav">
                <el-row class="mb-4">

                    <el-dropdown @command="handleStatusSelect" trigger="click" style="margin-right: 13px;">
                        <el-button type="primary" round plain class="el-dropdown-link">
                            Statut du bien
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="1">Reçu</el-dropdown-item>
                                <el-dropdown-item command="2">En cours</el-dropdown-item>
                                <el-dropdown-item command="3">Validé</el-dropdown-item>
                                <el-dropdown-item command="4">Refusé</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>

                    </el-dropdown>
                    <el-tag
                        v-for="tag in tags"
                            :key="tag.name"
                            class="mx-1"
                            closable
                            @close="handleClose(tag)"
                            :type="tag.type"
                        >
                        {{ tag.name }}
                    </el-tag>

                    <el-button type="danger" round plain style="position: absolute; right: 10px;">Effacer les filtres {{ count }} résultats</el-button>
                    
                    
                </el-row>
            </div>


            <div class="display_housings">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th>id</th>
                                <th>Note</th>
                                <th>Avis</th>
                                <th>created at pay</th>
                                <th>Opération</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(review, index) in reviews_to_validate" :key="index">
                                <td class="d-none d-md-table-cell">{{ review.id }}</td>
                                <td class="d-none d-md-table-cell">{{ review.stars }}</td>
                                <td class="d-none d-md-table-cell">{{ truncatedComment(review.comment) }} <el-button type="warning" @click="showMore(review.comment)">Voir plus</el-button></td>
                                <td class="d-none d-md-table-cell">{{ review.createdAt }}</td>
                                
                                <td>
                                    <el-row :gutter="20">
                                        <el-col :span="12" :offset="2">
                                            <el-button
                                                size="small"
                                                type="danger"
                                                @click="validateReview(review.id)"
                                                >Valider
                                            </el-button>
                                        </el-col>
                                        
                                    </el-row>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="d-flex align-items-start justify-content-between">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    @current-change="handlePageChange"
                    :current-page="internalPage"
                />
                <el-button type="primary" plain>Total: 30</el-button>
            </div>
            <br><br>
        </div>

        <el-dialog v-model="openModal" title="Visualiser le doc de payement" width="60%" draggable>
            <h6>
                Veillez vérifiez que le commentaire (AVIS) écrit par l'utilisateur est comforme a notre politique d'utilisaiton<br/><br/>
            </h6>

            
            <p><b>{{ wholeComment}}</b></p>
                    
        </el-dialog>
    </div>
</template>

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';
    import { ElNotification } from 'element-plus';

    export default {
        data() {
            return {
                reviews_to_validate: [],
                openModal: false,
                wholeComment: null,
            }
        },

        computed: {
            ...mapState('user', ['user']),
        },
    
        mounted() {
            this.fetchUser();
            this.fetchReviewsToValidate();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            

            async fetchReviewsToValidate() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/reviews-to-validate`);
                        this.reviews_to_validate = response.data.normalizedObject.upcomingReviewsToValidate;
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async validateReview(reviewId) {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/review/${reviewId}/review-validation`);
                        this.openNotification('success', response.data.normalizedObject.message)
                        this.fetchReviewsToValidate()
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            truncatedComment(text) {
                if (text.length > 100) {
                    return text.substring(0, 100) + '...';
                } else {
                    return text;
                }
            },

            showMore(comment) {
                this.openModal = true;
                this.wholeComment = comment;
            },

            openNotification(type, message) {
                ElNotification({
                    title: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize the type
                    message: message,
                    type: type,
                })
            },
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>

<style>
    
    .main_div {
        background-color: #eef4f6;
        height: 100vh !important;
    }
    .home-filter-nav {
        height: 60px;
        
        position: relative;
        margin-bottom: 6px;
        padding-top: 14px;
    }
    .el-tag {
        position: relative;
        top: 5px;
    }

    .thead-dark th {
        color: #777777;
        font-weight: none;
        font-size: 13px;
    }

    tbody td {
        font-size: 14px;
        height: 4vh;
        line-height: 4vh;
    }
    
    .custom-image-class {
        max-width: 70px; /* Adjust the width as per your requirement */
        height: auto;
    }

    tbody tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }
    thead tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }

    .preview {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
    }

</style>
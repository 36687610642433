<template>
    <div class="home">
      <Navbar/>
      <Hosts/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import Hosts from '@/components/Housings/Hosts.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
      name: 'HostsView',
      components: {
        Hosts,
        Navbar
      },
    };
  </script>
  
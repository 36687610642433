<template>
    <div class="home">
        <Navbar/>
        <SingleAdminHousing/>
    </div>
</template> 
  
<script>
    import Navbar from '@/components/Navbar/Navbar.vue';
    import SingleAdminHousing from '@/components/Housings/SingleAdminHousing.vue';
    export default {
        name: 'SingleAdminHousingView',
        components: {
            Navbar,
            SingleAdminHousing
        },
    };
</script>
  
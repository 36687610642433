<template>
    <div class="home">
      <Navbar/>
      <Orders/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import Orders from '@/components/Housings/Orders.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
      name: 'OrdersView',
      components: {
        Orders,
        Navbar
      },
    };
  </script>
  
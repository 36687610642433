<template>
    <div class="home">
      <Navbar/>
      <AllOrders/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import AllOrders from '@/components/Housings/AllOrders.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
      name: 'OrdersView',
      components: {
        AllOrders,
        Navbar
      },
    };
  </script>
  
<template>
    <div class="main_div">
        <div class="container">
            
            <div class="home-filter-nav">
                <el-row class="mb-4">

                    <el-dropdown @command="handleStatusSelect" trigger="click" style="margin-right: 13px;">
                        <el-button type="primary" round plain class="el-dropdown-link">
                            Statut du bien 
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="1">Reçu</el-dropdown-item>
                                <el-dropdown-item command="2">En cours</el-dropdown-item>
                                <el-dropdown-item command="3">Validé</el-dropdown-item>
                                <el-dropdown-item command="4">Refusé</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>

                    </el-dropdown>
                    <el-tag
                        v-for="tag in tags"
                            :key="tag.name"
                            class="mx-1"
                            closable
                            @close="handleClose(tag)"
                            :type="tag.type"
                        >
                        {{ tag.name }}
                    </el-tag>

                    <el-button type="danger" round plain style="position: absolute; right: 10px;">Effacer les filtres {{ count }} résultats</el-button>
                    
                    
                </el-row>
            </div>

            <div class="display_housings">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th>Bien</th>
                                <th>Statut payement</th>
                                <th class="d-none d-md-table-cell">Montant réservation</th>
                                <th class="d-none d-md-table-cell">Utilisateur</th>
                                <th class="d-none d-md-table-cell">Date d'entrée</th>
                                <th class="d-none d-md-table-cell">Date de sortie</th>
                                <th class="d-none d-md-table-cell">Date d'annulation</th>
                                <th class="d-none d-md-table-cell">Moyen payement</th>
                                <th class="d-none d-md-table-cell">Statut demande annulation</th>
                                <th class="d-none d-md-table-cell">Detail remboursement</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pendingCancellationRequests" :key="index">
                                
                                <td>{{ item.housing.name }}</td>
                                <td>
                                    {{ getPaymentStatusLabel(item.payment.status)[0] }} <i :class="getPaymentStatusLabel(item.payment.status)[1]" style="color: #1cc43e"></i>
                                </td>
                                <td class="d-none d-md-table-cell">
                                    {{ item.refund.amount }} €
                                </td>
                                <td class="d-none d-md-table-cell">{{ item.user.firstname }}</td>
                                <td class="d-none d-md-table-cell">{{ formattedDate(item.startDate) }}</td>
                                <td class="d-none d-md-table-cell">{{ formattedDate(item.endDate) }}</td>
                                <td class="d-none d-md-table-cell">{{ formattedDate(item.cancellationDate) }}</td>
                                <td class="d-none d-md-table-cell">{{ getPaymentMethodsLabel(item.payment.method) }}</td>
                                <td class="d-none d-md-table-cell">{{ getCancellationRequestStatus(item.status) }}</td>
                                <td class="d-none d-md-table-cell"><el-button type="danger" plain @click="askForCancellation(item.housing.id, item.cancellationDate ,item.payment.ord.id)">Voir remboursement</el-button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <el-dialog v-model="cancellation_modal_show" title="Annullation & Remboursement" width="30%" draggable>
                <h6>
                    Ce bien a une annulation de type {{ cancellation.cancellation_policy }} et vous êtes a {{ cancellation.daysToBooking }} jours de la date d'entrée.<br/>
                    Selon ces informations vous aurez un remboursement de {{ cancellation.refund }} %.
                </h6>
                <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">Cancel</el-button>
                    <el-button type="danger" @click="startCancellation()">
                        Annuler ma réservation
                    </el-button>
                </span>
                </template>
            </el-dialog>

            <div class="d-flex align-items-start justify-content-between">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :total="countPaginator()" 
                    @current-change="handlePageChange"
                    :current-page="internalPage"
                />
                <el-button type="primary" plain>Total: {{ count }}</el-button>
            </div>
            <br><br>
        </div>
    </div>
    
</template> 

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';

    export default {
        data() {
            return {
                tags: [],
                pendingCancellationRequests: [],
                selectedRows: [], 
                statusList: [],
                count: 0, 
                startingPage: 1,
                internalPage: 1,

                cancellation: {
                    cancellation_policy: null,
                    refund: null,
                    daysToBooking: null,
                    housing: null,
                    order: null,
                },

                cancellation_modal_show: false,
            }
        },

        computed: {
            ...mapState('user', ['user']),

            displayStatus(status) {
                switch (status) {
                    case '1':
                        return ['Reçu'];
                    case '2':
                        return 'En cours';
                    case '3':
                        return 'Validé';
                    case '4':
                        return 'Refusé';
                    default:
                        return 'Reçu';
                }
            },
        },
    
        mounted() {
            this.fetchUser();
            this.fetchPendingCancellationRequests();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            handleClose(tagToRemove) {
                this.tags = this.tags.filter(tag => tag.name !== tagToRemove.name);

                let status = tagToRemove.status
                if (this.statusList.includes(status)) {
                    let index = this.statusList.indexOf(status);
                    if (index > -1) {
                        this.statusList.splice(index, 1);
                    }
                }

                this.filterHousingsAdmin(this.statusList, this.startingPage);
            },

            async fetchPendingCancellationRequests() {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/cancellation-requests`);
                        this.pendingCancellationRequests = response.data.normalizedObject.CancellationRequests;
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            getPaymentStatusLabel(status) {
                if (status === 3) {
                    return ['En cours', 'fa-solid fa-circle-check'];
                } else if (status === 1) {
                    return ['Validée', 'fa-solid fa-circle-check']
                } else if (status === 2) {
                    return ['Rejeté', 'fa-solid fa-circle-check'];
                } else {
                    return ['Annulé', 'fa-solid fa-circle-check'];
                }
            },

            handleStatusSelect(command) {
                let status = parseInt(command);
            
                if (!this.statusList.includes(status)) {
                    this.statusList.push(status)
                }
               
                let tag = this.getTagForStatus(status);
                
                // If the tag exists and it's not already in the tags array, add it
                if(tag && !this.tags.some(t => t.name === tag.name)) {
                    this.tags.push(tag);
                }

                this.filterHousingsAdmin(this.statusList, this.startingPage);
            },

            getTagForStatus(status) {
                let tagMapping = {
                    1: { name: 'reçu', type: 'warning', status: 1 },
                    2: { name: 'en cours', type: 'info', status: 2 },
                    3: { name: 'validé', type: 'success', status: 3 },
                    4: { name: 'refusé', type: 'danger', status: 4 },
                };

                return tagMapping[status];
            },

            async filterHousingsAdmin(statuses, page) {
                try {
                    let statusQuery = statuses.map(status => `status[]=${status}`).join('&');
                    let pageQuery = `page=${page}`;
                    let limitQuery = `limit=10`;

                    let combinedQuery = `${statusQuery}&${pageQuery}&${limitQuery}`;
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/filter/housings?${combinedQuery}`);
                        this.housings = response.data.normalizedObject[0];
                        this.count = response.data.normalizedObject['count'];
                    }
                    
                } catch(err) {
                    console.log(err);
                } finally {
                    //this.reponseGotten = false
                }
            },

            handlePageChange(newPage) {
                if (newPage === this.startingPage) return; 

                this.startingPage = newPage;
                this.internalPage = newPage;
                this.filterHousingsAdmin(this.statusList, newPage);
            },

            countPaginator() {
                return this.count
            },

            isInstantBooking(item) {
                switch(item) {
                    case 1:
                        return true;
                    case 0:
                        return false;
                    default:
                        return true;
                }
                    
            },

            formattedDate(d) {
                const date = new Date(d);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); 
                const day = String(date.getDate()).padStart(2, '0');

                return `${year}-${month}-${day}`;
            },

            getPaymentMethodsLabel(method) {
                switch (parseInt(method)) {
                    case 0:
                        return 'Carte bancaire';
                    case 1:
                        return 'PayPal';
                    case 2:
                        return 'Point relais';
                    case 3:
                        return 'Barid card';
                    case 4:
                        return 'Par poste';
                    default:
                        break;
                }
            },

            getCancellationRequestStatus(status) {
                switch (parseInt(status)) {
                    case 1:
                        return 'Crée';
                    case 2:
                        return 'Acceptée';
                    case 3:
                        return 'Refusée';
                    case 4:
                        return 'En cours';
                    default:
                        break;
                }
            },

            async askForCancellation(housing_id, cancellation_date, order_id, ) {
                this.cancellation_modal_show = true
                
                this.getCancellationPolicy(housing_id, this.formattedDate(cancellation_date), order_id)
            },

            async getCancellationPolicy(housing, cancellation_date, order) {
                try {
                    let payload = {
                        'cancellationDate': cancellation_date
                    }

                    const response = await Axios.post(`/admin/${this.user.id}/housing/${housing}/order/${order}/cancellation_policy`, payload);
                    this.cancellation.cancellation_policy = response.data.normalizedObject.cancellation_policy;
                    this.cancellation.refund = response.data.normalizedObject.refund;
                    this.cancellation.daysToBooking = response.data.normalizedObject.daysSinceBooking;

                    this.cancellation.housing = housing
                    this.cancellation.order = order

                } catch (error) {
                    console.error(error);
                }
            },
            
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>

<style>
    
    .main_div {
        background-color: #eef4f6;
        height: 100vh !important;
    }
    .home-filter-nav {
        height: 60px;
        
        position: relative;
        margin-bottom: 6px;
        padding-top: 14px;
    }
    .el-tag {
        position: relative;
        top: 5px;
    }

    .thead-dark th {
        color: #777777;
        font-weight: none;
        font-size: 13px;
    }

    tbody td {
        font-size: 14px;
        height: 4vh;
        line-height: 4vh;
    }
    
    .custom-image-class {
        max-width: 70px; /* Adjust the width as per your requirement */
        height: auto;
    }

    tbody tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }
    thead tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }

</style>
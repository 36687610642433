<template>
    <div class="main_div">
        <div class="container">
            <div class="home-filter-nav">
                <el-row class="mb-4">

                    <el-dropdown @command="handleStatusSelect" trigger="click" style="margin-right: 13px;">
                        <el-button type="primary" round plain class="el-dropdown-link">
                            Statut du bien 
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="1">Reçu</el-dropdown-item>
                                <el-dropdown-item command="2">En cours</el-dropdown-item>
                                <el-dropdown-item command="3">Validé</el-dropdown-item>
                                <el-dropdown-item command="4">Refusé</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>

                    </el-dropdown>
                    <el-tag
                        v-for="tag in tags"
                            :key="tag.name"
                            class="mx-1"
                            closable
                            @close="handleClose(tag)"
                            :type="tag.type"
                        >
                        {{ tag.name }}
                    </el-tag>

                    <el-button type="danger" round plain style="position: absolute; right: 10px;">Effacer les filtres {{ count }} résultats</el-button>
                    
                    
                </el-row>
            </div>


            <div class="display_housings">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Token expiration</th>
                                <th>Méthode de payement</th>
                                <th>Somme</th>
                                <th>Statut</th>
                                <th>created at pay</th>
                                <th>Opération</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(payment, index) in payments" :key="index">
                                <td class="d-none d-md-table-cell">{{ payment.ord.user.lastname }}</td>
                                <td class="d-none d-md-table-cell">{{ payment.ord.user.firstname }}</td>
                                <td class="d-none d-md-table-cell">{{ payment.tokenExpiration }}</td>
                                <td class="d-none d-md-table-cell">{{ getPaymentMethodsLabel(payment.method) }}</td>
                                <td class="d-none d-md-table-cell">{{ payment.amount }}</td>
                                <td>
                                    {{ getPaymentStatusLabel(payment.status) }}
                                </td>
                                <td class="d-none d-md-table-cell">{{ payment.createdAt }}</td>
                                
                                <td>
                                    <el-row :gutter="20">
                                        <el-col :span="8">
                                            <el-button size="small" @click="checkDocument(payment.id)">Voir</el-button>
                                        </el-col>
                                       
                                        <el-col :span="8" :offset="2">
                                            <el-button
                                                size="small"
                                                type="danger"
                                                @click="validatePayment(payment.id)"
                                                >Valider
                                            </el-button>
                                        </el-col>
                                        
                                    </el-row>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="d-flex align-items-start justify-content-between">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    @current-change="handlePageChange"
                    :current-page="internalPage"
                />
                <el-button type="primary" plain>Total: 30</el-button>
            </div>
            <br><br>
        </div>

        <el-dialog v-model="openCheckDocModal" title="Visualiser le doc de payement" width="60%" draggable>
            <h6>
                Veillez vérifiez que le paiement  montionné dans ce document a bien été reçu<br/><br/>
                Une fois que c'est fait, vous pouvez valider le paiment.
            </h6>

            
            <img src="{{ this.aws_doc_url }}">
                    
                
        </el-dialog>
    </div>
</template>

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';

    export default {
        data() {
            return {
                payments: [],
                aws_doc_url: null,
                openCheckDocModal: false
            }
        },

        computed: {
            ...mapState('user', ['user']),
            
        },
    
        mounted() {
            this.fetchUser();
            this.fetchPayments();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            

            async fetchPayments() {
                try {
                    if(this.user) {
                        const housingId = this.$route.params.id;
                        const response = await Axios.get(`/admin/${this.user.id}/upcoming-payment-docs`);
                        this.payments = response.data.normalizedObject.upcomingPaymentDocs;
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            getPaymentStatusLabel(status) {
                switch (status) {
                    case 0:
                        return 'Annulé';
                    case 1:
                        return 'Réussi';
                    case 2:
                        return 'Refusé';
                    case 3:
                        return 'Commencé';
                    default:
                        break;
                }
            },

            getPaymentMethodsLabel(method) {
                switch (parseInt(method)) {
                    case 0:
                        return 'Carte bancaire';
                    case 1:
                        return 'PayPal';
                    case 2:
                        return 'Point relais';
                    case 3:
                        return 'Barid card';
                    case 4:
                        return 'Par poste';
                    default:
                        break;
                }
            },

            async checkDocument(paymentId) {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/payment/${paymentId}/check-doc`);
                        this.aws_doc_url = response.data.normalizedObject.aws_url;
                        this.openCheckDocModal = true;
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async validatePayment(paymentId) {
                try {
                    if(this.user) {
                        const response = await Axios.get(`/admin/${this.user.id}/payment/${paymentId}/payment-validation`);
                        this.fetchPayments()
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            }
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>

<style>
    
    .main_div {
        background-color: #eef4f6;
        height: 100vh !important;
    }
    .home-filter-nav {
        height: 60px;
        
        position: relative;
        margin-bottom: 6px;
        padding-top: 14px;
    }
    .el-tag {
        position: relative;
        top: 5px;
    }

    .thead-dark th {
        color: #777777;
        font-weight: none;
        font-size: 13px;
    }

    tbody td {
        font-size: 14px;
        height: 4vh;
        line-height: 4vh;
    }
    
    .custom-image-class {
        max-width: 70px; /* Adjust the width as per your requirement */
        height: auto;
    }

    tbody tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }
    thead tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }

    .preview {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
    }

</style>
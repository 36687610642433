/**
 * Simple Documentation
 * This file to create a base url to called in Axios
 */

import axios from 'axios';
import { accountService } from './account.service';
import router from '@/router';

const Axios = axios.create({
    baseURL: 'https://www.api.karry.fr'
})

Axios.defaults.headers['Content-Type'] = 'application/json';

/**
 * This to intersept token
 */
Axios.interceptors.request.use(request => {
    // To connect by adding the token as a bearer
    if (accountService.isLogged()) {
        request.headers.Authorization = 'Bearer '+ accountService.getToken()
    }

    return request;
})

/**
 * This to intersept response
 * Check if token has expired
 */
Axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status == 401) {
        accountService.logout()
        router.push('/auth');
    }

    throw error;
})

export default Axios

<template>
    <nav class="navbar navbar-expand-lg navbar-full-width">
        <div class="container">
            <a class="navbar-brand" href="/" style="color: rgb(248, 180, 34)">Brand</a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link :to="`/`" class="nav-link">Tableau de bord</router-link>
                    </li>
                
                    
                </ul>
            </div>

            <div class="navbar-nav ml-auto">
                <li class="nav-item">
                    
                    <el-dropdown class="profile_dropdown">
                        <el-image style="width: 50px; height: 50px; border-radius: 50% 50%; position: relative; " :src="url" :fit="fit"/>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>
                                    <div class="d-flex">
                                        <el-image style="width: 80px; height: 80px; border-radius: 50% 50%; position: relative; top: 3px; " :src="url" :fit="fit"/>
                                        <div style="padding: 13px 10px; line-height: 0.4;">
                                            <h6 style="font-weight: bold;">Ilyes ouakouak</h6>
                                            <p>Admin</p>
                                            <p><i class="fa-regular fa-envelope"></i>ouakouak.ilyes@gmail.com</p>
                                        </div>
                                    </div>
                                </el-dropdown-item>
                                <router-link to="/admin/parametres" class="nav-link" href="#">
                                    <el-dropdown-item divided>Paramètres</el-dropdown-item>
                                </router-link>
                                <router-link to="/admin/parametres" class="nav-link" href="#">
                                    <el-dropdown-item divided>Paramètres</el-dropdown-item>
                                </router-link>
                                <el-dropdown-item><el-button type="warning" class="col-12" plain @click="logout">Se deconnecter</el-button></el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </li>
            </div>
        </div>
    </nav>
</template>

<script>
    import { accountService } from '@/_services';
    export default {
        name: 'ManageHousingNavbar',
        data() {
            return {
                isLogged: accountService.isLogged(),
                url: require('@/assets/avatar.jpeg'),
            };
        },

        computed: {
            id() {
                return this.$route.params.id;
            }
        },

        methods: {
            logout() {
                accountService.logout();
                this.$router.push('/auth');
            }
        }
    }
</script>

<style>
    .navbar-full-width {
        width: 100vw;
        height: 70px;
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);
        border-bottom: 1px solid #c9c9c9 !important;
    }
 
    .el-dropdown-menu__item:hover {
        background-color: inherit !important;
        color: inherit !important;
    }

    @media (max-width: 992px) {
        .navbar-collapse {
            background-color: white; /* Change this to the color you prefer */
            margin-top: 10px; /* Add a top margin */
        }
        .navbar-toggler {
            border-color: rgba(0,0,0,.1);
        }
        
    }
    
</style>
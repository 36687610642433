<template>
    <div class="main_div">
        <div class="container">
            <div class="home-filter-nav">
                <el-row class="mb-4">

                    <el-dropdown @command="handleStatusSelect" trigger="click" style="margin-right: 13px;">
                        <el-button type="primary" round plain class="el-dropdown-link">
                            Statut du bien 
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="1">Reçu</el-dropdown-item>
                                <el-dropdown-item command="2">En cours</el-dropdown-item>
                                <el-dropdown-item command="3">Validé</el-dropdown-item>
                                <el-dropdown-item command="4">Refusé</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>

                    </el-dropdown>
                    <el-tag
                        v-for="tag in tags"
                            :key="tag.name"
                            class="mx-1"
                            closable
                            @close="handleClose(tag)"
                            :type="tag.type"
                        >
                        {{ tag.name }}
                    </el-tag>

                    <el-button type="danger" round plain style="position: absolute; right: 10px;">Effacer les filtres {{ count }} résultats</el-button>
                    
                    
                </el-row>
            </div>


            <div class="display_housings">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th></th>
                                <th>Nom du bien</th>
                                <th>Client</th>
                                <th>Statut de commande</th>
                                <th class="d-none d-md-table-cell">Date d'entrée</th>
                                <th class="d-none d-md-table-cell">Date de sortie</th>
                                <th class="d-none d-md-table-cell">Prix total</th>
                                <th class="d-none d-md-table-cell">Statut de payement</th>
                                <th class="d-none d-md-table-cell">Méthode de payement</th>
                                <th class="d-none d-md-table-cell">Date de payement</th>
                                <th class="d-none d-md-table-cell">Annulation</th>
                                <th class="d-none d-md-table-cell">Remboursement</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in orders" :key="index">
                                <td><input type="checkbox" v-model="selectedRows[index]"></td>
                                <td class="d-none d-md-table-cell">{{ order[0].housing.name }}</td>
                                <td class="d-none d-md-table-cell">{{ order[0].user.firstname }}</td>
                                <td>
                                    {{ getOrderStatusLabel(order[0].status) }}
                                </td>
                                <td class="d-none d-md-table-cell">{{ order[0].formattedStartDate }}</td>
                                <td class="d-none d-md-table-cell">{{ order[0].formattedEndDate }}</td>
                                <td class="d-none d-md-table-cell">{{ order[0].totalAmount }}</td>
                                <td class="d-none d-md-table-cell">{{ getPaymentStatusLabel(order[0].payment.status) }}</td>
                                <td class="d-none d-md-table-cell">{{ getPaymentMethodsLabel(order[0].payment.method) }}</td>
                                <td class="d-none d-md-table-cell">{{ order[0].payment.paymentDate }}</td>
                                <td class="d-none d-md-table-cell">{{ getCancellationRequestStatusLabel(order['status']) }}</td>
                                <td class="d-none d-md-table-cell">{{ order[0].refund ? getRefundStatusLabel(order[0].refund.status) : '' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="d-flex align-items-start justify-content-between">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    @current-change="handlePageChange"
                    :current-page="internalPage"
                />
                <el-button type="primary" plain>Total: 30</el-button>
            </div>
            <br><br>
        </div>
    </div>
</template>

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';

    export default {
        data() {
            return {
                orders: [],
                selectedRows: [],
            }
        },

        computed: {
            ...mapState('user', ['user']),
            
        },
    
        mounted() {
            this.fetchUser();
            this.fetchOrders();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            

            async fetchOrders() {
                try {
                    if(this.user) {
                        const housingId = this.$route.params.id;
                        const response = await Axios.get(`/admin/${this.user.id}/housing/${housingId}/orders`);
                        this.orders = response.data.normalizedObject;
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            getPaymentStatusLabel(status) {
                switch (status) {
                    case 0:
                        return 'Annulé';
                    case 1:
                        return 'Réussi';
                    case 2:
                        return 'Refusé';
                    case 3:
                        return 'Commencé';
                    default:
                        break;
                }
            },

            getOrderStatusLabel(status) {
                switch (parseInt(status)) {
                    case 0:
                        return 'Annulé';
                    case 1:
                        return 'En attente';
                    case 2:
                        return 'Validé';
                    case 3:
                        return 'Refusé';
                    default:
                        break;
                }
            },

            getPaymentMethodsLabel(method) {
                switch (parseInt(method)) {
                    case 0:
                        return 'Carte bancaire';
                    case 1:
                        return 'PayPal';
                    case 2:
                        return 'Point relais';
                    case 3:
                        return 'Barid card';
                    case 4:
                        return 'Par poste';
                    default:
                        break;
                }
            },

            getCancellationRequestStatusLabel(status) {
                switch (status) {
                    case 2:
                        return 'Acceptée';
                    case 3:
                        return 'Refusée';
                    case 4:
                        return 'En cours';
                    default:
                        break;
                }
            },

            getRefundStatusLabel(status) {
                switch (status) {
                    case 1:
                        return 'En cours';
                    case 2:
                        return 'Refusé';
                    case 3:
                        return 'Validé';
                    default:
                        break;
                }
            },

        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>

<style>
    
    .main_div {
        background-color: #eef4f6;
        height: 100vh !important;
    }
    .home-filter-nav {
        height: 60px;
        
        position: relative;
        margin-bottom: 6px;
        padding-top: 14px;
    }
    .el-tag {
        position: relative;
        top: 5px;
    }

    .thead-dark th {
        color: #777777;
        font-weight: none;
        font-size: 13px;
    }

    tbody td {
        font-size: 14px;
        height: 4vh;
        line-height: 4vh;
    }
    
    .custom-image-class {
        max-width: 70px; /* Adjust the width as per your requirement */
        height: auto;
    }

    tbody tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }
    thead tr {
        background-color: #FFFFFF;
        border: 5px solid #eef4f6;
    }

</style>
<template>
    <div class="container">
        <div class="d-flex align-items-start justify-content-between mt-2">
            <el-col :span="12">
                <el-card shadow="never">
                    <div class="general-info">
                        <div class="d-flex align-items-start justify-content-between">
                            <h3>Information général </h3>
                            <el-tooltip class="box-item" effect="light" content="Une fois validé, vous ne pouvez pas le changer directement. Contactez le service tech" placement="bottom">
                                <el-switch v-model="isValidated" @change="handleSwitchChange" :disabled="isValidated" style="--el-switch-on-color: #29bf12;"/>
                            </el-tooltip>
                        </div>
                        <el-input v-model="housingDetail.name" class="name"/>
                        <el-row :gutter="20">
                            <el-col :span="8"><el-input v-model="housingDetail.address" class="address"/> </el-col>
                            <el-col :span="4"><el-input v-model="housingDetail.city"/></el-col>
                            <el-col :span="6"><el-input v-model="housingDetail.district"/></el-col>
                            <el-col :span="6"><el-input v-model="housingDetail.zipcode"/></el-col> 
                        </el-row>
                    </div>
                        
                    <el-row :gutter="20">
                        <el-col :span="6">Voyageurs <el-input v-model="housingDetail.traveler"/></el-col>
                        <el-col :span="6">Chambres <el-input v-model="housingDetail.room"/></el-col>
                        <el-col :span="6">Lits <el-input v-model="housingDetail.bed"/></el-col>
                        <el-col :span="6">Salle de bain<el-input v-model="housingDetail.bathroom"/></el-col>
                    </el-row>
                    
                    <div class="d-flex">
                        <p>
                            {{ bookingType(housingDetail.bookingType)[0] }}
                            <el-tooltip :content="bookingType(housingDetail.bookingType)[2]" placement="bottom" effect="light">
                                <i :class="bookingType(housingDetail.bookingType)[1]" :style="{ color: bookingType(housingDetail.bookingType)[1] === 'fas fa-regular fa-circle-check' ? '#a0db8e' : '#daa520' }"></i>
                            </el-tooltip>
                        </p>
                        <el-switch v-model="isInstantBooking" @change="isInstantBookingSwitchChange" size="small" style="--el-switch-on-color: #f4a261; margin-left: 20px;"/>  
                    </div>

                    <el-row :gutter="20">
                        <el-col :span="8">
                            <p>Statut du bien</p>
                            <el-select v-model="housingDetail.status" @change="updateStatus" placeholder="Select Status">
                                <el-option v-for="s in statusOptions" :key="s" :label="getStatusLabel(s)" :value="s"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="16">
                            <p>Pourcentage Hôte</p>
                            <el-slider v-model="housingDetail.pricePercentage" show-input @change="handleSliderPriceChange" :disabled="housingDetail.type !== 'hotel' " />
                        </el-col>
                    </el-row>
                    
                </el-card>
            </el-col>
            <el-col :span="11">
                
                <el-card shadow="never">
                    <h3>Prochaines réservations </h3>
                    <div class="table-responsive">
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Client</th>
                                    <th>Statut</th>
                                    <th class="d-none d-md-table-cell">Date d'entrée</th>
                                    <th class="d-none d-md-table-cell">Date de sortie</th>
                                    <th class="d-none d-md-table-cell">Prix total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in orders" :key="index">
                                    <td class="d-none d-md-table-cell">{{ order.user.firstname }}</td>
                                    <td>
                                        {{ order.status }}
                                    </td>
                                    <td class="d-none d-md-table-cell">{{ order.formattedStartDate }}</td>
                                    <td class="d-none d-md-table-cell">{{ order.formattedEndDate }}</td>
                                    <td class="d-none d-md-table-cell">{{ order.totalAmount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <router-link :to="{ name: 'admin_orders', params: { id: housingId } }">Voir toute les réservation</router-link>
                </el-card>
            </el-col>
        </div>
    </div>
</template>

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';
    
    export default {
        data() {
            return {
                housingDetail: '',
                isValidated: false,
                isHousingValidated: false,
                isInstantBooking: false,
                orders: '',
                housingDetail: {
                    status: null
                },
                statusOptions: [2, 3, 4],
                price_percentage: 30,
            };
        },

        mounted() {
            this.fetchHousingDetails();
            this.fetchHousingAndUserOrders();

        },

        computed: {
            ...mapState('user', ['user']),
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            handleSwitchChange(newValue) {
                if (newValue) { 
                    this.validateAhousing()
                    this.isValidated = true
                }
            },
            
            isInstantBookingSwitchChange() {
                this.makeInstantBooking()
            },

            async fetchHousingDetails() {
                try {
                    const housingId = this.$route.params.id;
                    const response = await Axios.get(`admin/${this.user.id}/housing/${housingId}`);
                    this.housingDetail = response.data.normalizedObject;

                    if (this.housingDetail.isHousingValidated === true) {
                        this.isValidated = true;
                    }

                    if(this.housingDetail.bookingType === 1) {
                        this.isInstantBooking = true
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            async fetchHousingAndUserOrders() {
                try {
                    const housingId = this.$route.params.id;
                    const response = await Axios.get(`/admin/${this.user.id}/housing/${housingId}/coming-orders`);
                    this.orders = response.data.normalizedObject;

                } catch (error) {
                    console.error(error);
                }
            },

            async validateAhousing()
            {
                try {
                    const response = await Axios.post(`/admin/${this.user.id}/housing/${this.housingDetail.id}/validate`);
                    this.isHousingValidated = response.data.normalizedObject;
                    if (response.data.normalizedObject === false) {
                        this.isValidated = false;
                    }

                } catch (error) {
                    console.error(error);
                }
            },

            getStatusLabel(status) {
                if (status === 2) {
                    return 'En cours de validation';
                } else if (status === 3) {
                    return 'Validé';
                } else if (status === 4) {
                    return 'Rejeté';
                } else {
                    return 'Reçu';
                }
            },

            bookingType(type) {
                switch (type) {
                    case 1:
                        return ['Reservation instantanée', 'fas fa-regular fa-circle-check', 'Une fois le payement a été fait, votre réservation est confirmée immédiatement'];
                    case 0:
                        return ['Validation par le proprietaire', 'fas fa-regular fa-clock', 'Une fois le payement a été fait, votre réservation doit être validé par le hôte'];
                    default:
                        return ['Reservation instantané', 'fas fa-solid fa-ferry', 'Une fois le payement a été fait, votre réservation est confirmée immédiatement'];
                }
            },

            async makeInstantBooking() {
                try {
                    if(this.user) {
                        const housingId = this.$route.params.id;
                        let payload = {
                            'isInstantBooking' : this.isInstantBooking
                        }
                        const response = await Axios.put(`/admin/${this.user.id}/housing/${housingId}/update-booking-type`, payload);
                        this.housingDetail.bookingType = response.data.normalizedObject;

                        if (this.housingDetail.bookingType === 1) {
                            this.isInstantBooking = true
                        } else {
                            this.isInstantBooking = false
                        }

                        this.fetchHousingDetails()
                        
                    }
                    
                } catch (error) {
                    console.error(error);
                }
            },

            updateStatus() {
                this.updateStatusApiCall(this.housingDetail.status);
            },

            async updateStatusApiCall(newStatus) {
                try {
                    if(this.user) {
                        const housingId = this.$route.params.id;
                        let payload = {
                            'status' : newStatus
                        }
                        const response = await Axios.put(`/admin/${this.user.id}/housing/${housingId}/update-status`, payload);
                    }
                } catch (error) {
                    console.error(error);
                }
            },

            async updatePricePercentage(newPercentage) {
                try {
                    if(this.user) {
                        const housingId = this.$route.params.id;
                        let payload = {
                            'pricePercentage' : newPercentage
                        }
                        const response = await Axios.put(`/admin/${this.user.id}/housing/${housingId}/update-price-percentage`, payload);
                        this.fetchHousingDetails()
                    }
                } catch (err) {
                    console.error('API error:', error);
                }
            }, 

            handleSliderPriceChange(value) {
                this.updatePricePercentage(value);
            }
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    };

</script>

<style>
    
    .general-info .name{
        margin-bottom: 15px !important;
    }
    .general-info .address{
        margin-bottom: 15px !important;
    }

    .el-col {
        margin-bottom: 15px !important;
    }
    
    .thead-dark th {
        color: #777777;
        font-weight: none;
        font-size: 13px;
        border-right: 1px solid #e6d7d7;
    }

    tbody td {
        font-size: 14px;
        height: 4vh;
        line-height: 4vh;
        border-right: 1px solid #e6d7d7;
    }

    tbody tr {
        background-color: #FFFFFF;
        border: 1px solid #e6d7d7;
        
    }
    thead tr {
        background-color: #FFFFFF;
        border: 1px solid #e6d7d7;
    }
    
    .total-amount .total {
        border: 1px solid #f3dabf;
        color: #353434;
    }
    .total-amount .upcoming-earnings {
        border: 1px solid #c3ebeb;
        color: #353434;
    }
    .total-amount .past-earnings {
        border: 1px solid #f5d5e5;
        color: #353434;
    }
</style>
<template>
    <div class="home">
      <Navbar/>
      <HostsDocValidation/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import HostsDocValidation from '@/components/Housings/HostsDocValidation.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
      name: 'HostsDocValidationView',
      components: {
        HostsDocValidation,
        Navbar
      },
    };
  </script>
  
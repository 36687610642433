<template>
    <div class="container">
        <el-row :gutter="20" class="mt-5">
            <el-col :span="8">
                <el-card shadow="never">
                    <p>Pourcentage sur le Hôte</p>
                    <el-slider v-model="pricePercentageOnHost" show-input @change="updatePriceHostPercentage" />
                </el-card>
            </el-col>

            <el-col :span="8">
                <el-card shadow="never">
                    <p>Pourcentage sur le client</p>
                    <el-slider v-model="pricePercentageOnGuest" show-input @change="updatePriceGuestPercentage" />
                </el-card>
            </el-col>
        </el-row>
    </div>
   
</template>

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';

    export default {
        data () {
            return {
                pricePercentageOnHost: null,
                pricePercentageOnGuest: null
            }
        },

        mounted() {
            this.fetchApplicationParams();
        },
        
        computed: {
            ...mapState('user', ['user']),
        },

        methods: {
            ...mapActions('user', ['fetchUser']),
            async fetchApplicationParams() {
                try {
                    if(this.user) {
                        
                        const response = await Axios.get(`/admin/${this.user.id}/app-params`);
                        this.pricePercentageOnHost =  response.data.normalizedObject.find(obj =>obj.paramKey === 'price_percentage_on_host').pricePercentage;
                        this.pricePercentageOnGuest =  response.data.normalizedObject.find(obj =>obj.paramKey === 'price_percentage_on_guest').pricePercentage;
                    }
                } catch (err) {
                    console.error('API error:', err);
                }
            }, 

            async updatePriceHostPercentage(newPercentage) {
                try {
                    if(this.user) {
                        let payload = {
                            'pricePercentage' : newPercentage
                        }
                        const response = await Axios.put(`/admin/${this.user.id}/app-param-price-host-percentage`, payload);
                        this.fetchApplicationParams()
                    }
                } catch (err) {
                    console.error('API error:', err);
                }
            }, 

            async updatePriceGuestPercentage(newPercentage) {
                try {
                    if(this.user) {
                        let payload = {
                            'pricePercentage' : newPercentage
                        }
                        const response = await Axios.put(`/admin/${this.user.id}/app-param-price-guest-percentage`, payload);
                        this.fetchApplicationParams()
                    }
                } catch (err) {
                    console.error('API error:', err);
                }
            }, 
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>
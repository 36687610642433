<template>
    <div class="home">
      <Auth/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import Auth from '@/components/Security/Auth.vue';
    export default {
      name: 'HomeView',
      components: {
        Auth,
      },
    };
  </script>
  
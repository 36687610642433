<template>
    <div class="home">
      <Navbar/>
      <Housings/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import Housings from '@/components/Housings/Housings.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
      name: 'HousingsView',
      components: {
        Housings,
        Navbar
      },
    };
  </script>
  
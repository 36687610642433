<template>
    <div class="home">
      <Navbar/>
      <Cancellations/>
    </div>
  </template> 
  
  <script>
    // @ is an alias to /src
    import Cancellations from '@/components/Housings/Cancellations.vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
      name: 'CancellationsView',
      components: {
        Cancellations,
        Navbar
      },
    };
  </script>
  
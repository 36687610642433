<template>
    <div class="container">
        <el-row :gutter="20">
            <el-col :span="6"><div class="grid-content card-host-info" />
                <el-card class="box-card" v-if="host" shadow="never">
                    {{ host.lastname }} {{ host.firstname }}
                </el-card>
            </el-col>

            <el-col :span="18"><div class="grid-content ep-bg-purple card-host-main" />
                <el-card class="box-card" v-if="host" shadow="never">
                    {{ host.lastname }} {{ host.firstname }}
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Axios from "@/_services/caller.service";
    import { mapState, mapActions } from 'vuex';
    import { ElNotification } from 'element-plus';

    export default {
        data() {
            return {
                host: null
            }
        },

        computed: {
            ...mapState('user', ['user']),
        },
    
        mounted() {
            this.fetchUser();
            this.fetchHostDetails();
        },

        methods: {
            ...mapActions('user', ['fetchUser']),

            async fetchHostDetails() {
                try {
                    if(this.user) {
                        const hostId = this.$route.params.id;
                        let payload = {
                            'hostId': hostId
                        }

                        const response = await Axios.post(`admin/${this.user.id}/host`, payload);
                        this.host = response.data.normalizedObject.host;
                    }
                } catch (error) {
                    console.error(error);
                }
            },
        },

        created() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.$store.commit('user/setUser', JSON.parse(storedUser));
            }
        }
    }
</script>


<style>
    .card-host-info {
        margin-top: 20px;
    }

    .card-host-main {
        margin-top: 20px;
    }
</style>